import type { V2_MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { links } from '~/utils/links.ts'

export const meta: V2_MetaFunction = () => [{ title: 'Daniel Arrizza' }]

export default function Index() {
	return (
		<main className="flex min-h-screen flex-col items-center justify-center	">
			<h1 className="mb-6 px-4 text-center text-6xl font-bold">
				Welcome from Daniel Arrizza
			</h1>
			<p className="mb-10 px-10 text-center text-lg ">
				I am a software engineer, trainer, consultant, and entrepreneur based in
				Toronto, Canada.
			</p>
			<div className="flex flex-col space-y-4 text-4xl font-medium  md:flex-row  md:space-x-10 md:space-y-0 md:text-2xl">
				{links.map(link => (
					<Link
						key={link}
						to={'/' + link.toLowerCase()}
						className="hover:text-blue-500"
					>
						{link}
					</Link>
				))}
			</div>
		</main>
	)
}
